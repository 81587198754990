import Vue from 'vue'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import './assets/styles/main.scss'

axios.defaults.baseURL = 'https://vefglobal.net/api/'

axios.interceptors.response.use(function (response) {
    return response.data.data;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });

Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
}).$mount('#app')
