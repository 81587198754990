import axios from 'axios'

const state = () => ({
  list: [],
  upcomingList: [],
  liveList: [],
})

const getters = {
  list(state) {
     return state.list
  },
  upcomingList(state) {
    return state.upcomingList
  },
  liveList(state) {
    return state.liveList
  },
}

const actions = {
   async fetchList({commit}) {
     const res = await axios.get('events')
      commit('setList', res.events)
      commit('setUpcomingList', res.upcoming_events)
      commit('setLiveList', res.live_events)
   }
}

const mutations = {
  setList (state, data) {
    state.list = data
  },
  setUpcomingList(state, data) {
    state.upcomingList = data
  },
  setLiveList(state, data) {
    state.liveList = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
