<template>
  <div class="links-container">
    <div class="links" v-for="event in events" :key="event.id">
      <a :href="isLive ? event.live_url : event.register_url" target="_blank">
        <h3>{{ event.name }}</h3>
        <p>{{ event.options.customDate }}</p>
        <button>{{ isLive ? "Visit Now" : "Register Now" }}</button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  props: {
    events: {
      required: true,
    },
    isLive: {
      require: false,
      default: false,
    },
  },
  methods: {
    getEventDate(event) {
      if (!event.begin_at || !event.end_at) {
        return "";
      }
      const startDate = new Date(event.begin_at);
      const endDate = new Date(event.end_at);

      return (
        startDate.getDay() +
        " - " +
        endDate.getDay() +
        " " +
        this.monthNames[startDate.getMonth()] +
        " " +
        startDate.getFullYear()
      );
    },
  },
};
</script>
